// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '../../../../node_modules/@angular/material/index' as mat;
@use '../../../../libs/smex-module-cfn-ecm-frontend/src/styles/common-themes' as numen;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$smex-app-cfn-frontend-primary: mat.define-palette(
  numen.$numen-primary-palette
);
$smex-app-cfn-frontend-accent: mat.define-palette(
  numen.$numen-secondary-palette
);

// The warn palette is optional (defaults to red).
$smex-app-cfn-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$smex-app-cfn-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $smex-app-cfn-frontend-primary,
      accent: $smex-app-cfn-frontend-accent,
      warn: $smex-app-cfn-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($smex-app-cfn-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  .mdc-tooltip {
    font-size: small !important;
  }

  .mat-mdc-button:hover,
  .mat-mdc-icon-button:hover {
    background-color: #a61a1c19 !important;
  }
}
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
